<template>
    <b-card title="Edit Promocode">
        <b-col cols="12">
            <label>Batch Number</label>
            <b-form-input
                v-model="batch.batch"
                type="text"
                placeholder="Enter name"
                required
                disabled
            ></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Status</label>
            <b-form-select
                v-model="batch.status"
                placeholder="----- Select Status -----"
                :options="statusChoices">
            </b-form-select>
        </b-col>

        <b-col cols="12" class="my-1">
            <b-button variant="success" @click="doUpdate">Save</b-button>
        </b-col>
        <b-col cols="8" >
            
        </b-col>
        <b-col cols="4" class="my-1">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>

        <b-col cols="12" class="mt-1">
            <b-table
                :filter="search"
                :current-page="currentPage"
                :per-page="perPage"
                responsive
                show-empty
                :fields="referenceCodeFields"
                :items="referencesCodes"
            >

                <template #cell(status)="row">
                    <b-form-select
                        v-model="row.item.status"
                        placeholder="----- Select Status -----"
                        :options="referenceCodeStatusChoice"
                        :disabled = "row.item.status === 'Used'"
                        @change="(selected) => updateStatus(row.item._id, selected,row.item.referenceCode)">
                    </b-form-select>
                </template>
                <template #cell(usedDate)="row">
                    {{row.item.usedDate?row.item.usedDate :'-' | formatDate}}
                </template>
                <template #cell(useBy)="row">
                    {{ row.item.useBy }}
                    <!-- <div v-for="(items,i) in row.item" :key="i">
                        <p>{{ items['nameFirst']}} {{items['nameLast']}} </p>
                    </div> -->
                </template>
            </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            :total-rows="dataSearch"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            batch: {},
            referencesCodes: [],
            statusChoices: [
                { value: 'Active', text: 'Active' },
                { value: 'Inactive', text: 'Inactive' },
            ],
            referenceCodeFields: [
                { key: 'referenceCode', label: 'Reference Code' },
                { key: 'status', label: 'Status' },
                { key: 'usedDate', label: 'Use Date' },
                // { key: 'useBy', label: 'Customer' },
                { key: 'cert', label: 'Cert' },
            ],
            referenceCodeStatusChoice: [
                { value: 'Used', text: 'Used' },
                { value: 'Active', text: 'Active' },
                { value: 'Inactive', text: 'Inactive' },
            ],
            search: '',
            currentPage: 1,
            perPage: 5
        }
    },
    mounted() {
        this.getBatch(this.$route.params.id).then(data => {
            let { batch, referencesCodes } = data;

            this.batch = batch;
            this.referencesCodes = referencesCodes;
        });
    },
    computed: {
        
        dataSearch(){
            let paginationLength;
            paginationLength = this.referencesCodes.filter(el => {
                return el.referenceCode.includes(this.search);
            })
            if(this.search != ''){
                return paginationLength.length
            }else{
                return this.referencesCodes.length
            }
        },
    },
    methods: {
        ...mapActions({
            'getBatch': 'pcode/getBatch',
            'updateReferenceCode': 'pcode/updatePromoCodeReference',
            'updateBatch': 'pcode/updateBatch',
        }),
        doUpdate() {
            this.updateBatch(this.batch).then((updatedBatch) => {
                this.$bvToast.toast(`Update Batch Success`, {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                this.$router.go(-1);
            }).catch((e) => {
                this.$bvToast.toast(`Update Batch Failed`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
        },
        updateStatus(idReference, status, refCode) {
            this.updateReferenceCode({
                id: idReference,
                status: status,
            }).then((data) => {
                this.$bvToast.toast(`Update Reference Code : ${refCode} Success`, {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                // this.$router.go(-1);
            }).catch((e) => {
                this.$bvToast.toast(`Update Reference Code Failed`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            });
        }
    }    
}
</script>